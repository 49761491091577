<template>
  <h1 style="color: black;">Quiz Management</h1>
  <div class="row container-fluid" v-if="!isLoading">
    <div class="col-sm">
      <button type="submit" class="button btn-primary bi-arrow-repeat" @click="updateQuizzesByCourseId(261)"> Para Course
        Quizzes
      </button>
    </div>
    <div class="col-sm">
      <button type="submit" class="button btn-primary bi-arrow-repeat" @click="updateQuizzesByCourseId(268)"> Supervisor Course
        Quizzes
      </button>
    </div>
<!--    <div class="col-sm">-->
<!--      <b-tooltip target="_self" title="This will sync the statistics for both the instructor and para courses.">-->
<!--        <button type="submit" class="button btn-primary bi-arrow-repeat" @click="updateQuizStats"> All Quiz Statistics</button>-->
<!--      </b-tooltip>-->
<!--    </div>-->
  </div>
  <div class="row container-fluid">
    <div class="col-4" style="text-align: left">
        <label >Filter By:</label>
        <input class="form-control" type="search" v-model="searchQuery"
               aria-label="Search Query"/>
    </div>
  </div>
  <div v-if="!isLoading">
    <QuizTable :search-query="searchQuery"/>
  </div>
  <div v-else>
    <Loader :loading-value="loadingTextValue"/>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ref, onBeforeMount, computed} from "vue";
import axios from "axios";
import {
  updateQuizByCourseId,
  updateQuizReports,
  updateQuizzesFromCanvas
} from "@/views/SuperUserViews/Reporting/Quizzes/QuizApiCalls";
import QuizTable from "@/views/SuperUserViews/Reporting/Quizzes/QuizTable";
import Loader from "@/components/Loader";
import Swal from "sweetalert2";

export default {
  name: "QuizDashboard",
  components: {Loader, QuizTable},
  setup() {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const searchQuery = ref("");
    const isLoading = ref(true);
    const loadingTextValue = ref("");

    onBeforeMount(() => {
      isLoading.value = false;
    })

    async function updateQuizzesByCourseId(courseId) {
      isLoading.value = true;
      loadingTextValue.value = "Quizzes from Canvas";
      await axios.get(updateQuizByCourseId(courseId), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then(() => {
        Swal.fire({
          title: 'Successfully Updated Quizzes',
          icon: 'success'
        })
      }).finally(() => {
        isLoading.value = false;
      })
    }

    async function updateQuizzes() {
      isLoading.value = true;
      loadingTextValue.value = "Quizzes from Canvas";
      await axios.get(updateQuizzesFromCanvas(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then(() => {
        Swal.fire({
          title: 'Successfully Updated Quizzes',
          icon: 'success'
        })
      }).finally(() => {
        isLoading.value = false;
      })
    }

    async function updateQuizStats() {
      isLoading.value = true;
      loadingTextValue.value = "Quiz Reports from Canvas. Please be patient, this may take a while.";
      await axios.get(updateQuizReports(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then(() => {
        Swal.fire({
          title: 'Successfully Updated Quiz Reports',
          icon: 'success'
        })
      }).finally(() => {
        isLoading.value = false;
      })
    }

    return {
      loadingTextValue,
      isLoading,
      searchQuery,
      updateQuizzesByCourseId,
      updateQuizzes,
      updateQuizStats,
    }
  }
}
</script>

<style scoped>

</style>