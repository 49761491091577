<template>
    <div class="container-lg mt-2">
        <div v-if="!isLoading">
            <table id="quizTable" class="table table-bordered table-striped">
                <thead>
                <tr>
                    <th v-for="(value, name, index) in headers" :key="index" @click="sortTable(name)">
                        <i :class="name === prevSortCol ? sortIconStyle : `bi bi-sort-up`"></i>
                           {{ value }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="quiz in filteredQuizzes" :key="quiz">
                    <td v-for="(value, name, index) in headers" :key="index">
                        <p v-if="name === 'quizTitle'">
                            <router-link :to="{name: 'QuizReport', params: {idQuiz: quiz.idQuiz}}">{{
                                quiz[name]
                                }}
                            </router-link>
                        </p>
                        <div v-else-if="name === 'description'" v-html="quiz['description']"></div>
                        <p v-else>
                            {{ quiz[name] }}
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {getQuizDisplays} from "@/views/SuperUserViews/Reporting/Quizzes/QuizApiCalls";
import {sortBy} from "lodash/collection";

export default {
	name: "QuizTable",
	props: {
		searchQuery: String
	},
	setup(props) {
		const sort = ref(false);
		const quizzes = ref([]);
		const isLoading = ref(true);
		const updatedList = ref([]);
		const prevSortCol = ref("");
		const colReversed = ref(false);


		const headers = ref({
			quizTitle: "Title",
			canvasHtmlUrl: "Canvas Link",
			description: "Description"
		})

		onBeforeMount(() => {
			getQuizzes();
		})

		async function getQuizzes() {
			await axios.get(getQuizDisplays())
				.then((result) => {
					quizzes.value = result.data.sort((a, b) => a.idQuiz > b.idQuiz ? 1 : -1);
					updatedList.value = quizzes.value;
				}).finally(() => {
					isLoading.value = false;
				})
		}

		const sortTable = (col) => {
			sort.value = true;
			if (col !== prevSortCol.value) {
				updatedList.value = sortBy(quizzes.value, col);
				colReversed.value = false;
			} else {
				colReversed.value = !colReversed.value;
				updatedList.value = updatedList.value.reverse();
			}
			prevSortCol.value = col;
		}

		const filteredQuizzes = computed(() => {
			return updatedList.value.filter((quiz) => {
				return quiz.quizTitle.toLowerCase().includes(props.searchQuery.toLowerCase());
			})
		})

		const sortIconStyle = computed(() => {
			return !colReversed.value ? 'bi bi-sort-down' : 'bi bi-sort-up';
		})


		return {
			updatedList,
			isLoading,
			headers,
			filteredQuizzes,
			sortIconStyle,
			prevSortCol,
			sortTable
		}
	}
}
</script>

<style scoped>
td {
    border: 1px solid black;
    color: black;
}

th {
    border: 1px solid black;
    color: black;
}
</style>